import React from "react";
import "../css/cargando.css";
const loading = () => {
  return (
    <div id="fountainTextG">
      <div id="fountainTextG_1" className="fountainTextG">
        B
      </div>
      <div id="fountainTextG_2" className="fountainTextG">
        u
      </div>
      <div id="fountainTextG_3" className="fountainTextG">
        s
      </div>
      <div id="fountainTextG_4" className="fountainTextG">
        c
      </div>
      <div id="fountainTextG_5" className="fountainTextG">
        a
      </div>
      <div id="fountainTextG_6" className="fountainTextG">
        n
      </div>
      <div id="fountainTextG_7" className="fountainTextG">
        d
      </div>
      <div id="fountainTextG_8" className="fountainTextG">
        o
      </div>
      <div id="fountainTextG_9" className="fountainTextG">
        {" "}
        .{" "}
      </div>
      <div id="fountainTextG_10" className="fountainTextG">
        .
      </div>
      <div id="fountainTextG_11" className="fountainTextG">
        .
      </div>
    </div>
  );
};
export default loading;