const Rss = () => {
    return (
      <iframe
        id="doppler_subscription"
        src="https://app2.fromdoppler.com/Lists/FormProcessing/PublishedForm?IdForm=F%2bIkYzut2FJAl1HY6i9dxQ%3d%3d"
        height="980"
        width="380"
        //scrolling="yes"
        frameborder="no"
        //style="overflow:scroll"
      ></iframe>
    );
  };
  export default Rss;